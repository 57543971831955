import {PrismaClient} from '@prisma/client';

import {BaseLogger} from '@rockawayxlabs/observatory-utils';

import {Prisma} from './index';
import {attachEnabledLoggers, getEnabledLogDefinitions} from './log';

declare global {
  // eslint-disable-next-line no-var
  var __db__: PrismaClient | undefined;
}

// This is set in order to be aligned with the default precision of PostgreSQL DECIMAL type set by Prisma.
// See: https://www.prisma.io/docs/reference/api-reference/prisma-schema-reference#default-type-mappings-5
Prisma.Decimal.set({precision: 65, toExpNeg: -30});

export interface PrismaClientSettings {
  connectionPooling?: boolean;
  logger: BaseLogger;
}

/*
 * This is needed because in development we don't want to restart the server with every change, but we want to make sure
 * we don't create a new connection to the DB with every change either.
 *
 * In production we'll have a single connection to the DB.
 */
export function getOrCreatePrismaClient(settings: PrismaClientSettings): PrismaClient {
  const {logger} = settings;

  if (process.env.NODE_ENV === 'production') {
    logger.debug(`🔌 setting up prisma client`);
    return createPrismaClient(settings);
  }

  if (!global.__db__) {
    logger.debug(`🔌 setting up prisma client`);
    global.__db__ = createPrismaClient(settings);
  }
  return global.__db__;
}

function createPrismaClient({connectionPooling, logger}: PrismaClientSettings) {
  const url = createDatabaseUrl({connectionPooling, logger});
  const log = getEnabledLogDefinitions();

  const client = new PrismaClient({
    datasources: {db: {url}},
    log,
  });

  attachEnabledLoggers(client, logger);

  return client;
}

// export async function withPrismaClient<T>(
//   execute: (prisma: PrismaClient) => Promise<T>,
//   {connectionPooling = true, logger}: PrismaClientSettings
// ) {
//   logger.debug(`Connecting to database`);
//   const prisma = createPrismaClient({connectionPooling, logger});

//   try {
//     return await execute(prisma);
//   } finally {
//     logger.debug(`Disconnecting from database`);
//     await prisma.$disconnect();
//   }
// }

let prismaClient: PrismaClient | undefined;

export async function withPrismaClient<T>(
  execute: (prisma: PrismaClient) => Promise<T>,
  {connectionPooling = true, logger}: PrismaClientSettings
) {
  if (!prismaClient) {
    prismaClient = getOrCreatePrismaClient({connectionPooling, logger});
  }
  return execute(prismaClient);
}

function createDatabaseUrl({connectionPooling, logger}: PrismaClientSettings) {
  const {
    DATABASE_POOL_URL,
    DATABASE_URL,
    DATABASE_CONNECTION_LIMIT,
    PRISMA_CONNECTION_LIMIT,
    PRISMA_CONNECT_TIMEOUT,
    PRISMA_POOL_TIMEOUT,
  } = process.env;

  const url = (() => {
    if (connectionPooling) {
      if (DATABASE_POOL_URL) {
        const url = new URL(DATABASE_POOL_URL);
        url.searchParams.set('pgbouncer', 'true');
        return url;
      } else {
        logger.warn('DATABASE_POOL_URL environment variable not set, falling back to DATABASE_URL');
      }
    }

    if (!DATABASE_URL) {
      throw Error('DATABASE_URL environment variable not set');
    }

    return new URL(DATABASE_URL);
  })();

  const connectionLimit = PRISMA_CONNECTION_LIMIT || DATABASE_CONNECTION_LIMIT;
  if (connectionLimit) {
    logger.debug(`Setting connection limit to ${connectionLimit}`);
    url.searchParams.set('connection_limit', connectionLimit);

    if (!PRISMA_CONNECTION_LIMIT) {
      logger.warn('DATABASE_CONNECTION_LIMIT environment variable is deprecated, use PRISMA_CONNECTION_LIMIT instead');
    }
  }

  if (PRISMA_CONNECT_TIMEOUT) {
    logger.debug(`Setting connect timeout to ${PRISMA_CONNECT_TIMEOUT}`);
    url.searchParams.set('connect_timeout', PRISMA_CONNECT_TIMEOUT);
  }

  if (PRISMA_POOL_TIMEOUT) {
    logger.debug(`Setting pool timeout to ${PRISMA_POOL_TIMEOUT}`);
    url.searchParams.set('pool_timeout', PRISMA_POOL_TIMEOUT);
  }

  return url.toString();
}

export type AnyPrismaClient = PrismaClient | Prisma.TransactionClient;
