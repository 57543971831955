export function maxBigInt(...values: bigint[]): bigint {
  return values.reduce((max, value) => (value > max ? value : max), values[0] ?? 0n);
}

export function minBigInt(...values: bigint[]): bigint {
  return values.reduce((min, value) => (value < min ? value : min), values[0] ?? 0n);
}

export function generateBigIntArray(from: bigint, to: bigint, increment = 1n): bigint[] {
  if (increment === 0n) {
    return [];
  }
  let usedIncrement = increment;
  if ((from > to && increment > 0n) || (from < to && increment < 0n)) {
    usedIncrement = -increment;
  }

  const length = Number((to - from) / usedIncrement + 1n);
  return new Array(length).fill(0n).map((_, index) => from + BigInt(index) * usedIncrement);
}

export function generateBigIntRangesArray(from: bigint, to: bigint, size: number): [bigint, bigint][] {
  if (from > to) {
    throw new Error(`'from' must be less than or equal to 'to'`);
  }
  if (size <= 0) {
    throw new Error('size must be greater than 0');
  }

  const length = Math.ceil(Number(to - from + 1n) / size);

  return new Array(length)
    .fill(0n)
    .map((_, index) => [from + BigInt(index * size), minBigInt(to, from + BigInt(index * size + size - 1))]);
}

export function compareBigInt(a: bigint, b: bigint, order: 'asc' | 'desc' = 'asc') {
  if (a < b) {
    return order === 'asc' ? -1 : 1;
  } else if (a > b) {
    return order === 'asc' ? 1 : -1;
  } else {
    return 0;
  }
}

export function parseBigInt(value: unknown) {
  if (
    typeof value !== 'bigint' &&
    typeof value !== 'boolean' &&
    typeof value !== 'number' &&
    typeof value !== 'string'
  ) {
    return;
  }

  try {
    return BigInt(value);
  } catch {
    return;
  }
}

export function splitArrayIntoConsecutiveBigInts(values: bigint[], order: 'asc' | 'desc'): bigint[][] {
  return [...values]
    .sort((a, b) => compareBigInt(a, b, order))
    .reduce((groups, value) => {
      const latestGroup = groups[groups.length - 1] ?? [];
      const latestSet = new Set(latestGroup);

      if (latestSet.has(value - 1n) || latestSet.has(value) || latestSet.has(value + 1n)) {
        latestGroup.push(value);
      } else {
        groups.push([value]);
      }

      return groups;
    }, new Array<bigint[]>())
    .map(set => set.sort((a, b) => compareBigInt(a, b, order)));
}

export function findMissingBigIntRanges({from, to, values}: {from: bigint; to: bigint; values: bigint[]}) {
  const sortedValues = [...values].sort((a, b) => compareBigInt(a, b));

  const missingRanges = new Array<[bigint, bigint]>();

  let currentFrom = from;
  for (const value of sortedValues) {
    if (value > currentFrom) {
      missingRanges.push([currentFrom, value - 1n]);
    }
    currentFrom = value + 1n;
  }

  if (currentFrom <= to) {
    missingRanges.push([currentFrom, to]);
  }

  return missingRanges;
}
