export function formatOrdinal(ordinal: number) {
  const suffix = getOrdinalSuffix(ordinal);
  return `${ordinal}${suffix}`;
}

function getOrdinalSuffix(ordinal: number) {
  const pluralRule = new Intl.PluralRules('en', {type: 'ordinal'}).select(ordinal);

  switch (pluralRule) {
    case 'one':
      return 'st';
    case 'two':
      return 'nd';
    case 'few':
      return 'rd';
    default:
      return 'th';
  }
}
