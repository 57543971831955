import {Prisma} from '@prisma/client';

import {BaseLogger, sleep} from '@rockawayxlabs/observatory-utils';

import {PrismaErrorCode} from './PrismaErrorCode';

export const formatSearch = {
  contains: (keyword: string) => {
    const escaped = keyword
      .replace(/[^\w\s]/g, '')
      .replace(/\s\s+/g, ' ')
      .trim()
      .replace(/\s/g, ':*|');

    return escaped ? `${escaped}:*` : '';
  },
};

export function createZoneBlockTimeViewName(zoneId: number) {
  return `zone_${zoneId}_block_time`;
}

interface RetryTransactionOptions {
  errorCodes?: string[];
  logger: BaseLogger;
  maxRetries?: number;
}

export async function retryTransaction<T>(
  transaction: Promise<T>,
  {logger, maxRetries = 3}: RetryTransactionOptions
): Promise<T> {
  const errors: unknown[] = [];

  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    if (attempt > 1) {
      const waitMs = (attempt - 1) * 100 + Math.floor(Math.random() * 100);
      logger.debug(`Retrying transaction (${attempt}/${maxRetries}) in ${waitMs}ms...`);
      await sleep(waitMs);
    }

    try {
      return await transaction;
    } catch (error) {
      if (error instanceof Prisma.PrismaClientKnownRequestError && error.code === PrismaErrorCode.TransactionConflict) {
        logger.debug(error, `Transaction conflict (${attempt}/${maxRetries})`);
        errors.push(error);
        continue;
      }

      throw error;
    }
  }

  throw new AggregateError(errors, `Transaction failed after ${maxRetries} retries.`);
}
