import {withTimeout} from '../promise';
import {AbortTimeout} from './AbortTimeout';

interface Params {
  timeoutMs: number;
}

export function withAbortTimeout<T>(
  execute: (abortTimeout: AbortTimeout) => Promise<T>,
  {timeoutMs}: Params
): Promise<T> {
  const signal = AbortSignal.timeout(timeoutMs);
  const abortTimeout: AbortTimeout = {signal, timeoutMs};

  return withTimeout(execute(abortTimeout), timeoutMs);
}
