export interface FeatureFlags {
  nodeClaim: boolean;
  validatorOutages: boolean;
  validatorScoreHistory: boolean;
}

const defaultFeatureFlags: FeatureFlags = {
  nodeClaim: true,
  validatorOutages: false,
  validatorScoreHistory: true,
};

export function containsFeatureFlag(flags: string[], flag: keyof FeatureFlags) {
  return flags.some(f => f.toLowerCase() === flag.toLowerCase());
}

export function getFeatureFlagsFromEnv(env: Record<string, string | undefined>): FeatureFlags {
  if (env.APP_ENV !== 'production') {
    return defaultFeatureFlags;
  }

  const envFeatureFlags = parseFeatureFlags(env.FEATURE_FLAGS);

  return {
    nodeClaim: containsFeatureFlag(envFeatureFlags, 'nodeClaim'),
    validatorOutages: containsFeatureFlag(envFeatureFlags, 'validatorOutages'),
    validatorScoreHistory: containsFeatureFlag(envFeatureFlags, 'validatorScoreHistory'),
  };
}

export function parseFeatureFlags(flags?: string): string[] {
  return String(flags ?? '')
    .split(',')
    .map(flag => flag.trim().toLowerCase())
    .filter(Boolean);
}
