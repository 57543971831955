import {PathBuilder} from '../PathBuilder';

export class ValidatorsDetailLinkBuilder extends PathBuilder {
  public analysis() {
    return new PathBuilder([...this.path, 'analysis']);
  }

  public newsfeed() {
    return new PathBuilder([...this.path, 'newsfeed']);
  }

  public mev() {
    return new PathBuilder([...this.path, 'mev']);
  }
}
