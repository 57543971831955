import {registerCustom} from 'superjson';

import {bufferTransformer} from './buffer';
import {decimalTransformer} from './decimal';

export function registerCustomTransformers() {
  registerCustom(decimalTransformer, decimalTransformer.name);

  // We don't want to register Buffer transformer in the browser
  if (typeof Buffer !== 'undefined') {
    registerCustom(bufferTransformer, bufferTransformer.name);
  }
}
