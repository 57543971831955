export class AbortError extends Error {
  public readonly name = 'AbortError';
}

export class TimeoutError extends AbortError {
  // TODO set name to TimeoutError once we start using native fetch with AbortController
  // TODO not clear why we need TimeoutError once we start using AbortController for timeouts
  // leaving this here for now to avoid breaking changes

  constructor(public readonly timeoutMs: number) {
    super(`Timed out in ${timeoutMs}ms.`);
  }
}

export class TerminationError extends Error {
  public readonly name = 'TerminationError';

  constructor(signal: string) {
    super(`Terminated by ${signal} signal.`);
  }
}
