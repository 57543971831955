// eslint-disable-next-line no-restricted-imports
import {useLoaderData as useRemixLoaderData} from '@remix-run/react';
import {useMemo} from 'react';

import type {DeserializedData} from '../types';
import {deserialize} from '../utils/deserialize';

export function useLoaderData<T>() {
  const data = useRemixLoaderData();
  // @ts-expect-error remix changed how data type of loader is obtained
  // leaving this for now as its working the same as previous version
  // TODO: fix type infer on remix loader data
  return useMemo(() => deserialize<DeserializedData<T>>(data), [data]);
}
