/* eslint-disable no-restricted-imports */
import pino from 'pino';

import {TimeoutError} from './error';
import {withTimeout} from './promise';

const defaultOptions: pino.LoggerOptions = {
  level: 'debug',
};

function createLoggerTransport() {
  return pino.transport({
    targets: [
      ...(process.env.LOGTAIL_SOURCE_TOKEN
        ? [
            {
              target: '@logtail/pino',
              level: process.env.LOGTAIL_LOG_LEVEL ?? 'debug',
              options: {
                sourceToken: process.env.LOGTAIL_SOURCE_TOKEN,
              },
            },
          ]
        : []),
      {
        target: 'pino/file',
        level: process.env.STDOUT_LOG_LEVEL ?? (!process.env.LOGTAIL_SOURCE_TOKEN ? 'debug' : 'fatal'),
        options: {
          destination: 1,
        },
      },
    ],
  });
}

export function createLogger(options: pino.LoggerOptions = {}) {
  const mergedOptions = {...defaultOptions, ...options};

  if (process.env.NODE_ENV === 'development') {
    return pino(mergedOptions);
  }

  const transport = createLoggerTransport();
  const logger = pino(mergedOptions, transport).child({appVersion: process.env.APP_VERSION});

  if (!process.env.LOGTAIL_SOURCE_TOKEN) {
    logger.warn('LOGTAIL_SOURCE_TOKEN env variable not set');
  }

  return logger;
}

export async function flushLogger(logger: pino.Logger, timeoutMs = 1000) {
  try {
    return await withTimeout(
      new Promise<void>((resolve, reject) => {
        logger.flush(error => (error ? reject(error) : resolve()));
      }),
      timeoutMs
    );
  } catch (error) {
    if (error instanceof TimeoutError) {
      logger.warn(error, `Logger flush timed out after ${timeoutMs}ms`);
    } else {
      throw error;
    }
  }
}

export async function withLoggerFlush(log: (logger: pino.Logger) => void, logger: pino.Logger) {
  log(logger);
  await flushLogger(logger);
}

export type {BaseLogger, Logger} from 'pino';
