import {PathBuilder} from '../PathBuilder';
import {MevBlocksLinkBuilder} from './MevBlocksLinkBuilder';

export class MevLinkBuilder extends PathBuilder {
  public blocks() {
    return new MevBlocksLinkBuilder([...this.path, 'blocks']);
  }

  public validators() {
    return new PathBuilder([...this.path, 'validators']);
  }
}
