import {TimeoutError} from './error';

export function withTimeout<T>(promise: Promise<T>, ms?: number): Promise<T> {
  if (!ms) {
    return promise;
  }

  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => reject(new TimeoutError(ms)), ms);

    promise.then(
      response => {
        clearTimeout(timeoutId);
        resolve(response);
      },
      error => {
        clearTimeout(timeoutId);
        reject(error);
      }
    );
  });
}

export function isPromiseRejectedResult<T>(promise: PromiseSettledResult<T>): promise is PromiseRejectedResult {
  return promise.status === 'rejected';
}

export function isPromiseFulfilledResult<T>(promise: PromiseSettledResult<T>): promise is PromiseFulfilledResult<T> {
  return promise.status === 'fulfilled';
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function sleepAbortably(ms: number, abortSignal: AbortSignal) {
  let timeout: ReturnType<typeof setTimeout> | undefined;

  return new Promise<boolean>(resolve => {
    if (abortSignal.aborted) {
      resolve(true);
      return;
    }

    const onAbort = () => {
      clearTimeout(timeout);
      resolve(true);
    };

    timeout = setTimeout(() => {
      abortSignal.removeEventListener('abort', onAbort);
      resolve(false);
    }, ms);

    abortSignal.addEventListener('abort', onAbort, {once: true});
  });
}

export function waitForAbort(abortSignal: AbortSignal): Promise<void> {
  return new Promise(resolve => {
    abortSignal.addEventListener('abort', () => resolve(), {once: true});
  });
}
