import {PathBuilder} from '../PathBuilder';
import {ValidatorsDetailLinkBuilder} from './ValidatorsDetailLinkBuilder';

export class ValidatorsLinkBuilder extends PathBuilder {
  public detail(operatorAddress: string) {
    return new ValidatorsDetailLinkBuilder([...this.path, operatorAddress]);
  }

  public nodes() {
    return new PathBuilder([...this.path, 'nodes']);
  }

  public asn(asn: number | string) {
    const params = new URLSearchParams(this.params.toString());
    params.set('asn', asn.toString());
    return new ValidatorsLinkBuilder([...this.path], params);
  }

  public country(countryCode: string) {
    const params = new URLSearchParams(this.params.toString());
    params.set('country', countryCode);
    return new ValidatorsLinkBuilder([...this.path], params);
  }

  public isp(isp: string) {
    const params = new URLSearchParams(this.params.toString());
    params.set('isp', isp);
    return new ValidatorsLinkBuilder([...this.path], params);
  }
}
