// eslint-disable-next-line no-restricted-imports
import {useFetcher as useRemixFetcher} from '@remix-run/react';
import {useMemo} from 'react';

import type {DeserializedData} from '../types';
import {deserialize} from '../utils/deserialize';

export function useFetcher<T>() {
  const fetcher = useRemixFetcher();

  return useMemo(
    () => ({
      ...fetcher,
      // @ts-expect-error remix changed how data type of fetcher is obtained
      // leaving this for now as its working the same as previous version
      // TODO: fix type infer on remix fetcher data
      data: fetcher.data ? deserialize<DeserializedData<T>>(fetcher.data) : undefined,
    }),
    [fetcher]
  );
}
