/* eslint-disable no-fallthrough */
import {Prisma, PrismaClient} from '@prisma/client';

import {BaseLogger} from '@rockawayxlabs/observatory-utils';

export function getEnabledLogDefinitions() {
  if (!process.env.DATABASE_LOG_LEVEL) {
    return undefined;
  }

  const logDefinitions: Prisma.LogDefinition[] = [];

  switch (process.env.DATABASE_LOG_LEVEL) {
    case 'debug':
      logDefinitions.push({emit: 'event', level: 'query'});
    case 'info':
      logDefinitions.push({emit: 'event', level: 'info'});
    case 'warn':
      logDefinitions.push({emit: 'event', level: 'warn'});
    case 'error':
      logDefinitions.push({emit: 'event', level: 'error'});
  }

  return logDefinitions;
}

export function attachEnabledLoggers(
  client: PrismaClient<object, 'query' | 'info' | 'warn' | 'error'>,
  logger: BaseLogger
) {
  switch (process.env.DATABASE_LOG_LEVEL) {
    case 'debug':
      client.$on('query', event => logger.debug({event}, 'DB Query'));
    case 'info':
      client.$on('info', event => logger.info({event}, 'DB Info'));
    case 'warn':
      client.$on('warn', event => logger.warn({event}, 'DB Warn'));
    case 'error':
      client.$on('error', event => logger.error({event}, 'DB Error'));
  }
}
