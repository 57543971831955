import {rawEd25519PubkeyToRawAddress, rawSecp256k1PubkeyToRawAddress} from '@cosmjs/amino/build/addresses';
import {fromBech32, toBech32, toHex} from '@cosmjs/encoding';
import {Any} from 'cosmjs-types/google/protobuf/any';

export function accountAddressToOperator(operatorAddress: string) {
  const {prefix, data} = fromBech32(operatorAddress);
  return toBech32(`${prefix}valoper`, data);
}

export function consensusBech32ToHexAddress(bech32: string) {
  const {data} = fromBech32(bech32);
  return toHex(data).toUpperCase();
}

export function consensusHexToBech32Address(consensusHex: string, zonePrefix: string) {
  return toBech32(`${zonePrefix}valcons`, Buffer.from(consensusHex, 'hex'));
}

export function consensusPubKeyToBech32Address(
  consensusPubKey: {typeUrl?: string; value?: string},
  zonePrefix: string
) {
  const {typeUrl, value} = Any.fromJSON(consensusPubKey);

  // TODO handle other key types
  if (typeUrl.toLowerCase().includes('ed25519')) {
    return toBech32(`${zonePrefix}valcons`, rawEd25519PubkeyToRawAddress(value.slice(2)));
  } else {
    throw Error(`Unknown key type: ${typeUrl}`);
  }
}

export function consensusPubKeyToRawHexAddress(consensusPubKey: {typeUrl?: string; value?: string}) {
  const {typeUrl, value} = Any.fromJSON(consensusPubKey);

  if (String(typeUrl).toLowerCase().includes('ed25519')) {
    return toHex(rawEd25519PubkeyToRawAddress(value.slice(2))).toUpperCase();
  } else if (String(typeUrl).toLowerCase().includes('secp256k1')) {
    return toHex(rawSecp256k1PubkeyToRawAddress(value.slice(2))).toUpperCase();
  } else {
    throw Error(`Unknown key type: ${typeUrl}`);
  }
}

export function operatorAddressToAccount(operatorAddress: string) {
  const {prefix, data} = fromBech32(operatorAddress);
  return toBech32(prefix.replace(/(valoper|cncl)$/, ''), data);
}

export function operatorAddressToZonePrefix(operatorAddress: string) {
  const {prefix} = fromBech32(operatorAddress);
  return prefix.replace(/(valoper|cncl)$/, '');
}

export function polygonToConsensusPubKey(pubKey: string) {
  return {
    typeUrl: '/polygon', // TODO use better type
    value: pubKey,
  };
}

export function polygonSignerToConsensusAddress(signer: string) {
  return signer.replace(/^0x/, '').toUpperCase();
}

export function polygonConsensusToSignerAddress(consensusAddress: string) {
  return `0x${consensusAddress.toLowerCase()}`;
}

export function switchAddressPrefix(address: string, prefix: string) {
  const {data} = fromBech32(address);
  return toBech32(prefix, data);
}
