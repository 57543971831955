import {PathBuilder} from '../PathBuilder';
import {GovernanceProposalLinkBuilder} from './GovernanceProposalLinkBuilder';

export class GovernanceLinkBuilder extends PathBuilder {
  public proposal(proposalId: bigint | string) {
    return new GovernanceProposalLinkBuilder([...this.path, proposalId.toString()]);
  }

  public status(...statuses: string[]) {
    const params = new URLSearchParams(this.params.toString());
    params.delete('status');
    statuses.forEach(s => params.append('status', s));
    return new GovernanceLinkBuilder([...this.path], params);
  }
}
