export class PathBuilder {
  constructor(
    protected readonly path: string[],
    protected readonly params: URLSearchParams = new URLSearchParams()
  ) {}

  public toString(): string {
    const params = this.params.toString();
    return `/${this.path.join('/')}${params ? `?${params}` : ''}`;
  }
}
