import {PathBuilder} from '../PathBuilder';

export class ZoneDashboardLinkBuilder extends PathBuilder {
  public period(period: 'P1D' | 'P7D' | 'P30D' | 'P90D') {
    const params = new URLSearchParams(this.params.toString());
    params.set('period', period);
    return new ZoneDashboardLinkBuilder([...this.path], params);
  }

  public wait(waitForCreation: boolean) {
    const params = new URLSearchParams(this.params.toString());
    params.set('wait', String(waitForCreation));
    return new ZoneDashboardLinkBuilder([...this.path], params);
  }
}
