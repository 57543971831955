import type {CustomTransfomer} from 'superjson/dist/custom-transformer-registry';

import {Prisma} from '@rockawayxlabs/observatory-database';

export const decimalTransformer: CustomTransfomer<Prisma.Decimal, string> = {
  isApplicable: (v: unknown): v is Prisma.Decimal => Prisma.Decimal.isDecimal(v),
  serialize: (v: Prisma.Decimal) => v.toJSON(),
  deserialize: (v: string) => new Prisma.Decimal(v),
  name: 'Prisma.Decimal',
};
