import {PathBuilder} from './PathBuilder';

interface NewsfeedParams {
  activityTypes?: string[]; // TODO use ActivityType
  cursor?: string;
  validatorId?: number;
  zoneId: number;
}

interface ValidatorsParams {
  validatorIds: number[];
  zoneId: number;
}

export class DataLinkBuilder extends PathBuilder {
  public newsfeed({activityTypes, cursor, validatorId, zoneId}: NewsfeedParams) {
    const urlParams = new URLSearchParams({zoneId: String(zoneId)});

    if (cursor) {
      urlParams.set('cursor', String(cursor));
    }

    if (validatorId) {
      urlParams.set('validatorId', String(validatorId));
    }

    activityTypes?.forEach(type => urlParams.append('types', type));

    return new PathBuilder([...this.path, 'newsfeed'], urlParams);
  }

  public validators({validatorIds, zoneId}: ValidatorsParams) {
    const urlParams = new URLSearchParams({zoneId: String(zoneId)});

    validatorIds.forEach(id => urlParams.append('validatorIds', String(id)));

    return new PathBuilder([...this.path, 'validators'], urlParams);
  }
}
