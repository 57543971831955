import {DataLinkBuilder} from './DataLinkBuilder';
import {PathBuilder} from './PathBuilder';
import {ZoneLinkBuilder} from './ZoneLinkBuilder';

export class LinkBuilder {
  public static data() {
    return new DataLinkBuilder(['data']);
  }

  public static home() {
    return new PathBuilder([]);
  }

  public static validators() {
    return new PathBuilder(['validators']);
  }

  public static whitepaper() {
    return new PathBuilder(['whitepaper']);
  }

  public static terms() {
    return new PathBuilder(['terms-of-service']);
  }

  public static zone(zoneSlug: string) {
    return new ZoneLinkBuilder([zoneSlug]);
  }
}
