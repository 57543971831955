import {JsonValue} from '@prisma/client/runtime/library';

export * from '@prisma/client';
export * from './PrismaErrorCode';
export * from './client';
export * from './utils';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace PrismaJson {
    type ActivityChange = JsonValue | null;

    type ActivityData = Partial<{
      attempt: number;
      blockTimeMs: number;
      outageMs: number;
      proposalId: string;
      voteOptions: [string, number][];
    }> | null;

    type GovernanceProposalMetadata = Partial<{
      title: string;
      summary: string;
    }> | null;

    type JobParams = Partial<{
      correctTokenScale: number;
      dateBefore: string; // ISO date
      forceVotesSync: boolean;
      previousTokenScale: number;
      proposalId: string;
    }> | null;

    type ValidatorConsensusPubkey = Partial<{
      typeUrl: string;
      value: string;
    }> | null;
  }
}
