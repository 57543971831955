import {PathBuilder} from './PathBuilder';
import {GovernanceLinkBuilder} from './zone/GovernanceLinkBuilder';
import {MevLinkBuilder} from './zone/MevLinkBuilder';
import {ValidatorsLinkBuilder} from './zone/ValidatorsLinkBuilder';
import {ZoneDashboardLinkBuilder} from './zone/ZoneDashboardLinkBuilder';

export class ZoneLinkBuilder extends PathBuilder {
  public dashboard() {
    return new ZoneDashboardLinkBuilder([...this.path]);
  }

  public newsfeed() {
    return new PathBuilder([...this.path, 'newsfeed']);
  }

  public governance() {
    return new GovernanceLinkBuilder([...this.path, 'governance']);
  }

  public validators() {
    return new ValidatorsLinkBuilder([...this.path, 'validators']);
  }

  public mev() {
    return new MevLinkBuilder([...this.path, 'mev']);
  }

  public countries() {
    return new PathBuilder([...this.path, 'countries']);
  }

  public isps() {
    return new PathBuilder([...this.path, 'isps']);
  }

  public asns() {
    return new PathBuilder([...this.path, 'asns']);
  }

  public nodes() {
    return new PathBuilder([...this.path, 'nodes']);
  }
}
